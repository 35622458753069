import { compose } from "common/utils/core";
import logger from "common/utils/logger";

const noop = (...args) => logger.log("%c [MQ:gateway]:", "color: cornflowerblue", args);
const serialize = data => JSON.stringify(data);

const provider =
  window.ReactNativeWebView && window.ReactNativeWebView.postMessage
    ? window.ReactNativeWebView
    : {
        postMessage: noop
      };

const gateway = {
  postMessage: compose(provider.postMessage.bind(provider), serialize)
};

export default gateway;
