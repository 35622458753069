import mobileApp from "common/utils/mobile/app";
import { mergeDeep } from "common/utils/core";

const allowedProviders = ["google", "facebook"];

const connect = (
  provider,
  options = {
    redirectUrl: null,
    loggedInEmail: null
  }
) => {
  if (!allowedProviders.includes(provider)) throw new Error(`Invalid provider ${provider}`);

  mobileApp.mq.publish("auth", mergeDeep({ provider, action: "connect" }, options));
};
export default {
  connect
};
