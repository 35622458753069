import authOptions from "common/jiffy/mobile_app/mobile-app-auth";
import { on as onEvent } from "common/utils/ui/event";
import initDeprecatedMessageModal from "./deprecated-message-modal/deprecated-message-modal";
import "./user-auth-external.scss";

const authPaths = ["/login", "/signup"];
const triggerClass = ".js-app-native-auth";

window.jiffy = window.jiffy || {};

const initNativeAuth = () => {
  const callback = ({ target: $target }) => {
    const $button = $target.classList.contains(triggerClass)
      ? $target
      : $target.closest(triggerClass);
    const { pathname } = window.location;
    const redirectUrl = authPaths.includes(pathname) ? "/account/signins" : pathname;

    authOptions.connect($button.dataset.provider, { redirectUrl });
  };

  onEvent("click", triggerClass, callback, true);
};

export default () => {
  if (window.jiffy.nativeAuth) return;

  initDeprecatedMessageModal();
  initNativeAuth();
  window.jiffy.nativeAuth = true;
};
