import { on as onEvent } from "common/utils/ui/event";
import "./deprecated-message-modal.scss";

const deprecatedClass = ".js-deprecated-message";
const $$getModal = () => $(".js-deprecated-message-modal");

const initDeprecationMessage = () => {
  const callback = e => {
    e.preventDefault();

    $$getModal().modal("show");
  };

  onEvent("click", deprecatedClass, callback, true);
};

export default initDeprecationMessage;
